@import 'colors';
@import 'mixin';

h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

label {
  @extend .display-flex;
  @extend .flex-direction-column;
  font-size: 12px;
  span {
    color: $my-lilla;
    text-transform: uppercase;
    font-weight: 600;
  }
  input,
  textarea,
  select {
    color: $white;
    margin-top: 10px;
    background: $my-chart;
    padding: 20px;
    @extend .bsbb;
    @extend .trstn;
  }
  input,
  select {
    height: 60px;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
