@import 'colors';
@import 'mixin';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $bkg;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  vertical-align: top;
  color: $text-color;
  border: 0;
  outline: none;
}
textarea {
  resize: none;
}

a,
button {
  cursor: pointer;
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: $blue-grey-900;
  color: $white;
}

::-moz-selection {
  background-color: $blue-grey-900;
  color: $white;
}

input,
select,
textarea {
  padding: 20px;
  background: $white;
  @include border-radius(10px);
  @extend .bsbb;
  width: 100%;
}
textarea {
  min-height: 250px;
}

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
